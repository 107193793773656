import React from 'react';

import { Layout, SEO } from '../components';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="u-mt--lg">
      <div className="l-container">
        <h1 className="c-heading c-heading--h1">
          {'404'}
        </h1>
        <p>{'Sorry the page you\'re looking for seems to be absent.'}</p>
        <img
          src="https://s3.eu-west-2.amazonaws.com/ryanpotter.co.nz/images/confused-travolta.gif"
          className="c-confused-travolta"
          alt="Confused travolta"
          draggable={false}
        />
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
